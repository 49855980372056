import { defineStore } from 'pinia'
import { PublicKey } from '@solana/web3.js'
import { BaseMessageSignerWalletAdapter } from '@solana/wallet-adapter-base'

type WalletState = {
	wallet: BaseMessageSignerWalletAdapter | null
	publicKey: PublicKey | null
	isConnected: boolean
	isConnecting: boolean
}

export const useWalletStore = defineStore('wallet', {
	state: () =>
		({
			wallet: null,
			publicKey: null,
			isConnected: false,
			isConnecting: false,
		} as WalletState),
	actions: {
		async connectWallet(wallet: BaseMessageSignerWalletAdapter) {
			try {
				this.isConnecting = true

				await wallet.connect()

				this.wallet = wallet
				this.publicKey = wallet.publicKey
				this.isConnected = true

				wallet.on('disconnect', () => {
					this.wallet = null
					this.publicKey = null
					this.isConnected = false
				})
			} catch (error) {
				console.error('Error connecting to wallet:', error)
				throw error
			} finally {
				this.isConnecting = false
			}
		},
		async disconnectWallet() {
			try {
				if (this.wallet) {
					await this.wallet.disconnect()
				}
			} catch (error) {
				console.error('Error disconnecting wallet:', error)
				throw error
			}
		},
	},
})
